import React from "react";
import {FormControl, FormLabel, TextField} from "@material-ui/core";
import {noOp} from "./CardForm";

const toTitleCase = (value) => {
  if(!value || value.length === 0) {
    return "";
  } else {
    return String(value)
      .replace(/\s\s+/g, ' ')
      .split(" ")
      .map(word => word.length > 0 ? word.charAt(0).toUpperCase() + word.substring(1) : word)
      .join(" ");
  }
};

const CardholderField = ({value, error, disabled, onChange = noOp}) => (
  <FormControl fullWidth>
    <FormLabel htmlFor="card-holder">Cardholder name</FormLabel>
    <TextField
      id="card-holder"
      name="cardHolderName"
      value={toTitleCase(value)}
      error={error !=null}
      helperText={error}
      disabled={disabled}
      onChange={(event) => {
        const value = event.currentTarget.value.replace(/[^\w\s]/g, "");
        onChange(value);
      }}
    />
  </FormControl>
)

export default CardholderField;
