import React from "react";
import {FormControl, FormLabel, TextField} from "@material-ui/core";
import {noOp} from "./CardForm";

const ExpiryField = ({value, error, disabled, onChange=noOp}) => {
  const textValue = value.length < 2 ? value : `${value.slice(0, 2)}/${value.slice(2,4)}`;

  return (
    <FormControl fullWidth>
      <FormLabel htmlFor="expiry">Expiry</FormLabel>
      <TextField
        id="expiry" name="expiry"
        inputProps={{
          inputmode: "numeric",
          pattern: "[0-9]*"
        }}
        placeholder="MM/YY"
        error={error != null}
        disabled={disabled}
        helperText={error}
        value={textValue}
        variant="outlined"
        onKeyDown={event => {
          const cursorLocation = event.target.selectionStart;
          if(event.key === "Backspace" && cursorLocation === 3) {
            event.preventDefault();
            onChange(`${value.slice(0, 1)}${value.slice(2)}`);
          } else if(event.key !== "Backspace" && cursorLocation >= 5) {
            event.preventDefault();
          }
        }}
        onFocus={event => event.currentTarget.select()}
        onChange={event => {
          const value = event.currentTarget.value.replace(/[^\d]/g, '').slice(0, 4);
          onChange(value);
        }}
      />
    </FormControl>
  )
};



export default ExpiryField;
