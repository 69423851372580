export const luhnCheck = (ccNumber) => {
  if(typeof ccNumber !== "string") {
    ccNumber = String(ccNumber);
  }
  if (/[^0-9-\s]+/.test(ccNumber)) return false;
  ccNumber = ccNumber.replace(/\D/g, "");

  let sum = 0;
  for(let index = 0; index < ccNumber.length; index++) {
    let digit = Number.parseInt(ccNumber.charAt(ccNumber.length - index - 1));
    if(index % 2 ) {
      digit *= 2;
      if(digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
  }
  return (sum % 10) === 0;
}
