import React from "react";
import {FormControl, FormLabel, TextField} from "@material-ui/core";
import {noOp} from "./CardForm";

const CCVField = ({inputRef, value, disabled, error, onChange=noOp, maxLength=3}) => (
  <FormControl fullWidth>
    <FormLabel htmlFor="ccv">CCV</FormLabel>
    <TextField
      inputRef={inputRef}
      id="ccv" name="ccv"
      variant="outlined"
      inputProps={{
        inputmode: "numeric",
        pattern: "[0-9]*",
        maxLength: maxLength
      }}
      value={value}
      disabled={disabled}
      error={error != null}
      helperText={error}
      placeholder="123"
      onChange={event => {
        const value = event.currentTarget.value.replace(/[^\d]/g, '').slice(0,maxLength);
        onChange(value);
      }}
    />
  </FormControl>
)


export default CCVField;
