import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import "./index.css";

const searchParams = new URLSearchParams(window.location.search);
const theme = searchParams.has("Theme") && searchParams.get("Theme");
if(theme === "1") {
  document.querySelector("body").classList.add("dark");
}
ReactDOM.render(<App theme={theme}/>, document.getElementById('root'));

