import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, makeStyles} from "@material-ui/core";
import CreditCardInput from "./CreditCardInput";
import CCVField from "./CCVField";
import ExpiryField from "./ExpiryField";
import CardholderField from "./CardholderField";
import {luhnCheck} from "../utils";
import {getPaymentDetails, submitPayment, tokenizeCard} from "../api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import config from "../config";

dayjs.extend(customParseFormat);
export const noOp = () => {}

const CardForm = ({theme, onSuccess = noOp, onFailure = noOp}) => {

  const [PAN, setPAN] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [ccv, setCCV] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const sessionId = sessionStorage.getItem("sessionId")

  const getPaymentInformation = () => {
    getPaymentDetails(sessionId)
        .then((response) => {
          console.log(response.data);
          setLoading(false);
        })
  };
  const ccvRef = useRef();

  // runs only once.
  useEffect(() => {
    getPaymentInformation();
  }, [])

  useEffect(() => {
    if(expiry.length === 4 && ccvRef.current) {
      ccvRef.current.focus();
    }
  }, [expiry]);

  const validatePAN = (PAN) => {
    if(!PAN) {
      return "Please enter a credit card number";
    } else if(!luhnCheck(PAN)) {
      return "The credit card number is invalid";
    } else {
      return null;
    }
  }

  const supportCartType = (PAN) => {
    if (PAN && PAN.length >= 2) {
      const typeCheck2Digit=parseInt(PAN.substring(0,2));
      if (!amexEnabled && (typeCheck2Digit===34 || typeCheck2Digit===37)) {
        return "Amex is not supported.";
      } else {
        return null;
      }
    } else {
      return "The credit card number is invalid.";
    }
  }

  const validateCardholderName = (cardHolderName) => {
    if(!cardHolderName) {
      return "Please enter cardholder's name";
    } else {
      return null;
    }
  }

  const validateExpiry = (expiry) => {
    if(!expiry) {
      return "Please enter expiry date"
    } else {
      const expiryISODate = `20${expiry.slice(2, 4)}-${expiry.slice(0,2)}`;
      const expiryDate = dayjs(expiryISODate, "YYYY-MM", true).add(1, "month");
      if(!expiryDate.isValid() || !dayjs().isBefore(expiryDate)) {
        return "Expiry date is invalid"
      }
    }
    return null;
  }

  const validateCCV = (ccv) => {
    if(!ccv || ccv.length < 3) {
      return "Please enter a valid CCV"
    } else {
      return null;
    }
  }

  const submit = () => {
    const errors = {
      pan: validatePAN(PAN) || supportCartType(PAN),
      cardholder: validateCardholderName(cardHolderName.trim()),
      expiry: validateExpiry(expiry),
      ccv: validateCCV(ccv)
    };
    setErrors(errors);
    let errorCount = 0;
    Object.keys(errors).forEach(key => {
      if(errors[key]) {
        errorCount++;
      }
    });
    if(errorCount === 0) {
      setSubmitted(true);
      submitPayment(sessionId, {
        FullName: cardHolderName,
        CardNo: PAN,
        ExpiryDate: expiry,
        CVV: ccv,
      }).then(() => {
        onSuccess();
        return this;
      }).catch((error) => {
        onFailure(error);
      });
    }
  }

  const styleClasses = makeStyles(theme => ({
    formPage: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    image: {
      flex: "0 0 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 240,
      "& img": {
        height: 180,
        width: "auto"
      }
    },
    form: {
      flex: "1 1 auto",
      margin: "1rem 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    buttonBar: {
      flex: "0 0 0",
      textAlign: "center"
    }
  }))();
  //const imgPath = theme === "1" ? "/credit-card-dark.png" : "/credit-card-light.png";
  const imgPathWithAmex =  "/cards3.png" ;
  const imgPathWithoutAmex =  "/credit_card.gif" ;
  const { amexEnabled } = config

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <div className={styleClasses.formPage}>
      <div className={styleClasses.image}>
        {amexEnabled && (<img alt="credit card" src={imgPathWithAmex}/>)}
        {!amexEnabled && (<img alt="credit card" src={imgPathWithoutAmex}/>)}
        {/*<img alt="credit card" src="/credit_card.gif"/> //with animation disabled */}
      </div>
      <form
        noValidate={true}
        className={styleClasses.form}
        onSubmit={event => {
          event.preventDefault();
          submit();
        }}
      >
        <div>
          <Box mb={4}>
            <CreditCardInput value={PAN} error={errors.pan} disabled={submitted}
                             onChange={PAN => {
                               setPAN(PAN);
                             }}/>
          </Box>
          <Box mb={4}>
            <Grid container>
              <Grid item xs={12}>
                <CardholderField value={cardHolderName} error={errors.cardholder} disabled={submitted}
                                 onChange={cardholderName => {
                                   setCardHolderName(cardholderName);
                                 }}/>
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Grid container>
              <Grid item xs={6}>
                <Box pr={1}>
                  <ExpiryField value={expiry} error={errors.expiry} disabled={submitted}
                               onChange={(expiry) => {
                                 setExpiry(expiry);
                               }}/>
                </Box>

              </Grid>
              <Grid item xs={6}>
                <Box pl={1}>
                  <CCVField inputRef={ccvRef} value={ccv} error={errors.ccv} maxLength={4}
                            disabled={submitted}
                            onChange={(ccv) => {
                              setCCV(ccv);
                            }}/>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>

        <Box className={styleClasses.buttonBar} mt={1}>
          <Button
            className={styleClasses.buttonBar}
            variant="contained"
            type="submit"
            size="large"
            disabled={submitted}
            color="primary">
            Submit Payment
          </Button>
        </Box>
      </form>
    </div>
  );
}
export default CardForm;
