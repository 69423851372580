import {createMuiTheme} from "@material-ui/core";

const colours = {
  "otrLime": "rgba(224, 223, 0, 1)",
  "richBlack": "rgba(1, 1, 1, 1)",
  "momentumGrey": "rgba(72, 73, 74, 1)",
  "spaceGrey": "rgba(130, 130, 130, 1)"
}

const theme = createMuiTheme({
  typography: {
    fontFamily: "BentonSansRegular"
  },
  palette: {
    background: {
      default: "#252525"
    },
    text: {
      primary: "#fff",
      secondary: colours.spaceGrey,
    },
    primary: {
      main: colours.otrLime
    }
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 180,
        borderRadius: 33,
        height: 45,
        fontSize: 15,
        fontWeight: 900,
        fontFamily: 'BentonSansBold'
      },
      label: {
        marginTop: 3
      }
    },
    MuiTextField: {
      root: {
        fontSize: "1.2rem",
        "& :before": {
          borderBottom: `1px solid ${colours.spaceGrey}`
        }
      }
    },
    MuiFormLabel : {
      root: {
        marginBottom: 8,
      }
    },
    MuiOutlinedInput: {
      root: {
        "& fieldset": {
          border: `1px solid ${colours.spaceGrey}`,
          borderRadius: 0
        }
      }
    }
  }
});

export default theme;
