const axios = require('axios');

export const tokenizeCard = (data) => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has("tokenize")) {
    return fetch(queryParams.get("tokenize"), {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((response) => response.data)
  } else {
    return Promise.reject("No tokenize URL specified.");
  }
}


export const getPaymentDetails = (sessionId) => {
    return axios.get(`/form/v1/payment/${sessionId}/data`)
}
export const submitPayment = (sessionId, data) => {
  return axios.post(`/form/v1/payment/${sessionId}/submit`, data)
}
