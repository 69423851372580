import React, {useRef, useState} from "react";
import {FormControl, FormLabel, makeStyles, TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {noOp} from "./CardForm";
import config from "../config";

const CreditCardInput = ({value, error, disabled, onChange = noOp}) => {
  const ref = useRef();
  //const panParts = [useState(""), useState(""), useState(""), useState("")]

  const styleClasses = makeStyles(theme => ({
    creditCardInput: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridColumnGap: "1rem",
    },
    panField: {
      "& input": {
        textAlign: "center"
      }
    },
    error: {
      color: theme.palette.error.main,
      fontSize: "0.75rem",
      marginTop: "0.5rem"
    }
  }))();

  /*const onPANChange = (i) => (event) => {

    const inputs = Array.from(ref.current.querySelectorAll("input"));
    let remainder = "";
    const newValue = inputs[i].value.replace(/[^\d]/g, '');
    for(let loop = i; loop < panParts.length; loop++) {
      const combined = remainder + (loop === i ? newValue : panParts[loop][0]);
      panParts[loop][1](combined.slice(0, 4));
      remainder = combined.slice(4);

      if(i=== panParts.length - 1) {
        panParts[i][1](newValue.slice(0, 7));
      }
    }


    let PAN = inputs.reduce(
      (result, input) => result + input.value, "");
    onChange(PAN);
    if(event.currentTarget.selectionStart >= 4 && i < inputs.length - 1) {
      inputs[i + 1].focus();
    }
  }

  const textFields = [];
  for(let i=0; i < 4; i++) {
    textFields.push(React.createElement(TextField, {
      key: `pan-${i}`,
      inputProps: {
        inputmode: "numeric",
        pattern: "[0-9]*"
      },
      className: styleClasses.panField,
      name: `pan-part-${i + 1}`,
      fullWidth: true,
      variant: "outlined",
      size: "small",
      value: panParts[i][0],
      onChange: onPANChange(i),
      onFocus: (event => event.currentTarget.select())
    }))
  }*/

  const formatCC = (ccNumber) => {
    const { amexEnabled } = config

    // supports Amex, Master Card, Visa, and Discover
    // parameter 1 ccid= id of credit card number field
    // parameter 2 ctid= id of credit card type field

    var ccNumString=ccNumber.replace(/[^0-9]/g, '');

    // mc, starts with - 51 to 55
    // v, starts with - 4
    // dsc, starts with 6011, 622126-622925, 644-649, 65
    // amex, starts with 34 or 37
    var typeCheck = ccNumString.substring(0, 3);
    var cType='';
    var block1='';
    var block2='';
    var block3='';
    var block4='';
    var formatted='';

    if  (typeCheck.length===3) {
      var typeCheck2Digit=parseInt(typeCheck.substring(0,2));
      var typeCheck3Digit=parseInt(typeCheck)
      if (typeCheck2Digit >= 40 && typeCheck2Digit <= 49) {
        cType='Visa';
      }
      else if (typeCheck2Digit >= 51 && typeCheck2Digit <= 55) {
        cType='Master Card';
      }
      else if ((typeCheck2Digit >= 60 && typeCheck2Digit <= 62) || (typeCheck2Digit === 64) || (typeCheck2Digit === 65)) {
        cType='Discover';
      }
      else if (amexEnabled && (typeCheck2Digit===34 || typeCheck2Digit===37)) {
        cType='American Express';
      }
      else if ((typeCheck3Digit>=300 && typeCheck3Digit <=305) || typeCheck3Digit===309 || typeCheck2Digit === 36 || typeCheck2Digit === 38 || typeCheck2Digit === 39) {
        cType='Diners Club';
      }
      else {
        cType='Invalid';
      }
    }

    // all support card types have a 4 digit first block
    block1 = ccNumString.substring(0, 4);
    if (block1.length===4) {
      block1=block1 + ' ';
    }

    if (cType === 'Visa' || cType === 'Master Card' || cType === 'Discover') {
      // for 4X4 cards
      block2 = ccNumString.substring(4, 8);
      if (block2.length===4) {
        block2=block2 + ' ';
      }
      block3 = ccNumString.substring(8, 12);
      if (block3.length===4) {
        block3=block3 + ' ';
      }
      block4 = ccNumString.substring(12, 16);
    } else if (amexEnabled && (cType === 'American Express')) {
      // for Amex cards
      block2 =  ccNumString.substring(4, 10);
      if (block2.length===6) {
        block2=block2 + ' ';
      }
      block3 =  ccNumString.substring(10, 15);
      block4='';
    } else if (cType == 'Diners Club') {
      // diners
      block2 =  ccNumString.substring(4, 10);
      if (block2.length===6) {
        block2=block2 + ' ';
      }
      block3 =  ccNumString.substring(10, 14);
      block4='';
    }
    else if (cType === 'Invalid') {
      // for Amex cards
      /*console.log('Invalid');
      block1 =  typeCheck;
      block2='';
      block3='';
      block4='';*/
      return ccNumString;
    }

    formatted=block1 + block2 + block3 + block4;
    //ccType for credit card type
    return formatted;
  }

  return (

    /*<div>
      <div ref={ref} className={styleClasses.creditCardInput}>
        {textFields}
      </div>

      {error ? (
        <div>
          <span className={styleClasses.error}>{error}</span>
        </div>
      ): null}
    </div>*/

    <FormControl fullWidth>
      <FormLabel htmlFor="pan">Credit Card:</FormLabel>
      <TextField
        id="pan"
        name="pan"
        variant="outlined"
        disabled={disabled}
        inputProps={{
          inputmode: "numeric",
          maxLength: 20
        }}
        value={value}
        error={error !=null}
        helperText={error}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{marginRight: '10px'}} >
                <CreditCardIcon/>
            </InputAdornment>
          )
        }}
        onChange={(event) => {
          const value = event.currentTarget.value
          if (value.replace(/\D+/g, "").length >= 14) {
            event.currentTarget.value = formatCC(value)
          } else {
           event.currentTarget.value = value.replace(/\D+/g, "");
          }
          onChange(event.currentTarget.value)
        }}
      />
    </FormControl>

  )
}

export default CreditCardInput;
