import React, {useState} from "react";
import {MuiThemeProvider} from "@material-ui/core";
import OTRThemeDark from "./OTRTheme-dark";
import OTRThemeLight from "./OTRTheme-light";
import CardForm from "./components/CardForm";

const App = ({theme}) => {
  const [appState, setAppState] = useState("cardInput");

  //const otrTheme = OTRThemeDark;
  const otrTheme = theme === "1" ? OTRThemeDark : OTRThemeLight;
  return (
    <MuiThemeProvider theme={otrTheme}>
      {appState === "cardInput" ?
        <CardForm theme={theme}/> : null}
    </MuiThemeProvider>
  );
};

export default App;
